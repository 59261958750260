.carouselWrapper {
  margin: 24px auto 0;
  width: 1340px;
  position: relative;
  padding: 2px 0;
}

@media only screen and (max-width: 1080px)  {
  .carouselWrapper {
    margin: 24px 0 0 0;
    width: 100%;
  }
  .carouselWrapper::after {
    display: none;
  }
  .carouselWrapper::before {
    display: none;
  }
}

.carouselWrapper::after {
  content: '';
  position: absolute;
  height: calc(100% + 1px);
  width: 20%;
  background: linear-gradient(90deg, rgba(252, 244, 233, 0) 3.35%, #FCF4E9 24.92%);
  top: 0;
  right: 0;
  z-index: 10;
}

.carouselWrapper::before {
  content: '';
  position: absolute;
  height: calc(100% + 1px);
  width: 20%;
  background: linear-gradient(270deg, rgba(252, 244, 233, 0) 3.35%, #FCF4E9 24.92%);
  left: 0;
  top: 0;
  z-index: 10;
}

.itemWrapper {
  padding: 22px 44px;
  font-size: 18px;
  line-height: 24px;
  font-family: inferi-normal, sans-serif;
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto;
}

@media only screen and (min-width: 1081px) and (max-width: 1400px)  {
  .itemWrapper {
    padding: 15px 12px;
    font-size: 17px;
  }
  .carouselWrapper {
    width: 1080px;
  }
}

.keyword {
  font-family: inferi-normal-italic, sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
}

.bulbWrapper {
  position: relative;
}

.bulbWrapper > svg {
  position: absolute;
  top: -5px;
}
