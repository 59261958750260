.loading {
  display: none;
}

.pageWrapper {
  max-height: calc(100vh - 87px);
  height: calc(100vh - 87px);
  overflow-y: scroll;
  padding-right: 17px;
  width: calc(100% + 17px);
}

.resultsHeading {
  padding-top: 56px;
  text-align: center;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 24px;
}

.motto {
  margin-top: 10px;
  text-align: center;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.fakePrint {
  position: fixed;
  z-index: 1;
  right: calc(100% + 120px);
  width: 1080px;
  height: 1920px;
}

.fakeMantra {
  width: 1080px;
  height: 1920px;
  padding: 60px;
  font-family: inferi-normal, sans-serif;
  font-size: 120px;
  line-height: 1.25;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
}

.fakeMantra > div > span >  svg {
  width: 140px;
  height: 100px;
}

.fakeMantra > div > span >  br {
  display: none;
}

.fakeMantra > div:nth-child(1) {
  flex: 1;
}

.downloadLogo {
  text-align: right;
}

.mantra {
  margin-top: 8px;
  font-family: inferi-normal, sans-serif;
  font-size: 40px;
  line-height: 1.25;
  text-align: center;
}

.mantraSvg > svg {
  color: var(--tertiary-4);
}

.shareHint {
  margin-top: 72px;
  font-size: 12px;
  font-family: basis-grotesque-pro, sans-serif;
  text-align: center;
}

.shareButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 16px;
}

.shareButtonInner {
  display: flex;
  gap: 8px;
}

.downloadDesc {
  margin: 12px auto 0;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.downloadButton {
  text-align: center;
  margin-top: 16px;
}

.restart {
  text-align: center;
  padding-bottom: 56px;
  margin-top: 32px;
}

.shapeWrapper {
  display: flex;
}

.headingNext {
  padding-top: 56px;
  text-align: center;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 24px;
}

.believeDesc {
  text-align: center;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 15px;
  line-height: 20px;
  width: 32%;
  margin: 10px auto 0;
}

.focusArea {
  margin: 16px auto 0;
  width: 55%;
  background-color: var(--secondary-2);
  padding: 24px;
  border: 1px solid var(--secondary-2);
  border-radius: 8px;
}

.focusHeader {
  font-family: basis-grotesque-mono, sans-serif;
  letter-spacing: 0.1em;
  font-size: 12px;
  line-height: 16px;
}

.focusTitle {
  font-family: inferi-normal, sans-serif;
  font-size: 32px;
  margin-top: 16px;
}

.focusSections {
  display: flex;
  gap: 16px;
}

.firstSection {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 15px;
  line-height: 20px;
  flex: 1;
}

.firstSection > ul {
  margin: 0;
  color: black;
  list-style-type: none;
  font-size: 15px;
  line-height: 20px;
}

.firstSection > ol {
  margin: 8px;
  color: black;
  font-size: 12px;
  line-height: 16px;
}


.firstSection > ul > li::before {
  content: '';
  border: 1px solid var(--primary-2);
  background-color: var(--secondary-2);
  border-radius: 100%;
  width: 6px;
  height: 6px;
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
}

.firstSection > ol > li::marker {
  color: var(--primary-2);
}

.hint {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 8px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 1px solid var(--primary-2);
  background-color: var(--secondary-2);
  padding: 0 5px 0 3px;
  color: var(--primary-2);
}

.thirdSection {
  font-family: inferi-normal, sans-serif;
  font-size: 20px;
  line-height: 24px;
  flex: 1;
}

.avatarWrapper {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.avatar {
  background-color: var(--primary-1);
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.avatarDesc {
  flex: 1;
}

.name {
  font-family: basis-grotesque-pro, sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
}

.description {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.playList {
  display: flex;
  width: calc(55% + 48px);
  margin: 15px auto 0;
  gap: 100px;
}

.playList > div {
  flex: 1;
}

.playListRight {
  padding: 40px 0 0 0;
}

.playListHeader {
  font-family: basis-grotesque-mono, sans-serif;
  letter-spacing: 0.1em;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.playlistDesc {
  font-family: inferi-normal, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
}

.playListInner {
  display: flex;
  margin-top: 30px;
  align-items: center;
  gap: 10px;
}

.playListBanner {
  font-family: inferi-normal, sans-serif;
  font-size: 30px;
  line-height: 32px;
  padding: 2px 6px 4px;
  border-radius: 8px;
  display: flex;
  flex-flow: column nowrap;
  height: 96px;
  width: 90px;
}

.playListBanner > span {
  flex: 1;
}

.playListSmall {
  font-family: basis-grotesque-mono, sans-serif;
  letter-spacing: 0.1em;
  font-size: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 6px;
}

.playListSmall > svg {
  width: 11px;
  height: 7px;
}

.playListCode {
  flex: 1;
}

.playListCodeDesc {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.playListCodeImg {
  background-color: transparent;
}

.playListWave {
  margin-top: 50px;
  display: flex;
}

.appCards {
  background-color: var(--secondary-2);
  padding: 60px 0;
  text-align: center;
}

.cardsTitle {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 24px;
  line-height: 28px;
}

.cardsWrapper {
  display: flex;
  gap: 10px;
  width: calc(55vw + 48px);
  margin: 12px auto 0;
}

.card {
  background-color: var(--tertiary-4);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--secondary-2);
  gap: 16px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.cardTitle {
  font-family: basis-grotesque-pro, sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
}

.place {
  font-size: 12px;
  color: var(--primary-3);
  margin-top: 8px;
  display: flex;
  gap: 8px;
}

.cardContent {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 15px;
  text-align: left;
  margin-top: 24px;
  flex: 1;
}

.cardButton {
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  margin-top: 24px;
}

.aboutWave {
  display: flex;
  background-color: var(--secondary-2);
}

.aboutWrapper {
  background-color: var(--primary-3);
  color: var(--tertiary-4);
}

.aboutContent {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding: 48px;
  gap: 12px;
}

.aboutContent > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.aboutContent > div > img {
  margin: 0 auto;
}

.title {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 24px;
}

.Desc {
  margin-top: 16px;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.Button {
  margin-top: 48px;
}

@media only screen and (max-width: 1080px) {

  .loading {
    position: absolute;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    background-color: #ecdccd;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .downloadButton > button {
    padding: 12px 10px;
    font-size: 14px;
  }

  .pageWrapper {
    max-height: calc(100vh - 67px);
    height: calc(100vh - 67px);
    width: 100%;
    padding-right: 0;
  }

  .resultsHeading {
    text-align: left;
    padding: 10px 24px 0 24px;
  }

  .motto {
    text-align: left;
    padding: 0 24px;
  }

  .mantra {
    text-align: left;
  }

  .downloadDesc {
    text-align: left;
    padding:  0 24px;
  }

  .headingNext {
    text-align: left;
    width: calc(100vw - 48px);
    padding: 0 24px;
    margin-top: 12px;
  }

  .believeDesc {
    text-align: left;
    width: calc(100vw - 48px);
    padding: 0 24px;
  }

  .focusArea {
    width: calc(100% - 100px);
    margin: 12px 24px;
  }

  .focusSections {
    flex-flow: column nowrap;
    gap: 16px;
  }

  .firstSection > ul {
    padding: 0 14px;
  }

  .playList {
    flex-flow: column nowrap;
    width: calc(100% - 48px);
  }

  .cardsWrapper {
    width: calc(100vw - 48px);
  }

  .aboutContent {
    flex-flow: nowrap;
    flex-direction: column-reverse;
    width: calc(100vw - 48px);
    padding: 24px 24px;
  }

  .aboutContent > div > img {
    margin: 0;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px)  {
  .focusArea {
    width: 70%;
  }

  .cardsWrapper {
    width: calc(70% + 48px);
  }

  .playList {
    width: calc(70% + 48px);
  }
}

@media only screen and (min-width: 1080px) and (max-width: 1200px)  {
  .focusArea {
    width: 80%;
  }

  .cardsWrapper {
    width: calc(80% + 48px);
  }

  .playList {
    width: calc(80% + 48px);
  }
}



