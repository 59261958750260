@font-face {
    font-family: 'inferi-normal';
    src: url('./Inferi-normal.otf') format('opentype');
    src: url('./Inferi-normal.woff2') format('woff2'),
    url('./Inferi-normal.woff') format('woff');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inferi-normal-italic';
    src: url('./inferi-normal-italic.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'basis-grotesque-mono';
    src: url('./basis-grotesque-mono.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'basis-grotesque-pro';
    src: url('./basis-grotesque-pro.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}