.input {
  width: 100%;
  background-color: var(--tertiary-4);
  border: 1px solid var(--secondary-2);
  border-radius: 10px;
  padding: 14px 16px;
  color: var(--secondary-3);
  font-size: 16px;
}

.input:focus {
  border: 1px solid var(--secondary-2);
  outline: none;
}

.input::placeholder {
  color: var(--secondary-3);
}