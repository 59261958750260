:root {
  --primary-1: #f95647;
  --primary-2: #ef308d;
  --primary-3: #831a4a;
  --secondary-1: #fcf4e9;
  --secondary-2: #ecdccd;
  --secondary-3: #be9191;
  --secondary-4: #765454;
  --secondary-5: #f5907a;
  --tertiary-1: #d6deba;
  --tertiary-2: #f9b146;
  --tertiary-3: #282725;
  --tertiary-4: #ffffff;
  --red: #d8532e;
}

:global .primary-3 {
  color: var(--primary-3);
}

:global .secondary-3 {
  color: var(--secondary-3);
}

:global .tertiary-3 {
  color: var(--tertiary-3)
}

:global .tertiary-4 {
  color: var(--tertiary-4);
}

:global .bg-transparent {
  background-color: transparent;
}

:global .bg-primary-3 {
  background-color: var(--primary-3);
}

:global .bg-primary-2 {
  background-color: var(--primary-2);
}

:global .bg-secondary-1 {
  background-color: var(--secondary-1);
}


:global .bg-secondary-2 {
  background-color: var(--secondary-2);
}

:global .bg-secondary-5 {
  background-color: var(--secondary-5);
}

:global .bg-primary-3 {
  background-color: var(--primary-3);
}

:global .bg-tertiary-2 {
  background-color: var(--tertiary-2);
}

:global .bg-tertiary-1 {
  background-color: var(--tertiary-1);
}