.pageWrapper {
  max-height: calc(100vh - 90px);
  height: calc(100vh - 90px);
  overflow-y: scroll;
  padding-right: 17px;
  width: calc(100% + 17px);
}

.pageWrapperInner {
  height: calc(100vh - 90px);
  position: relative;
}

@media only screen and (max-width: 1080px) {
  .pageWrapper {
    max-height: calc(100vh - 70px);
    height: calc(100vh - 70px);
    width: 100%;
    padding-right: 0;
  }

  .pageWrapperInner {
    width: calc(100% - 48px);
    padding: 0 24px;
    height: calc(100vh - 70px);
  }
}


.title {
  font-family: inferi-normal, sans-serif;
  font-size: 64px;
}

.self {
  font-family: inferi-normal-italic, sans-serif;
  font-size: 64px;
}

.headerWrapper {
  margin: auto;
  text-align: center;
}

.titleWrapper {
  line-height: 0.9;
  padding-top: 8px;
}

.subHeaderWrapper {}

.subHeader {
  font-family: basis-grotesque-mono, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.descWrapper {
  margin-top: 16px;
  text-align: center;
}

.description {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 15px;
}

.firstLabelGroup {
  text-align: left;
  font-size: 12px;
  font-family: basis-grotesque-pro, sans-serif;
  color: var(--secondary-4);
}

.firstLabelGroup > span:first-child {
  flex: 50%;
}

.firstLabelGroup > span:nth-child(2) {
  flex: 50%;
}

.firstInputGroup {
  display: flex;
  gap: 12px;
  max-width: 452px;
  margin: 0 auto;
}

.inputsMobile {
  display: block;
  text-align: center;
}

.firstInputGroup > div:first-child {
  margin: 8px auto 0;
  width: 237px;
}

.firstInputGroup > div:nth-child(2) {
  width: 237px;
  margin: 8px auto 0;
}

.firstInputGroup > div > input {
  width: 178px;
}

@media only screen and (max-width: 1080px) {
  .firstInputGroup > div > input {
    width: 203px;
  }
}

.inputError > input {
  border: 1px solid var(--red);
}

.error {
  font-size: 12px;
  text-align: left;
  color: var(--red);
}

.secondLabelGroup {
  display: flex;
  gap: 12px;
  max-width: 237px;
  margin: 8px auto 0;
  font-size: 12px;
  font-family: basis-grotesque-pro, sans-serif;
  color: var(--secondary-4);
}

.secondInputGroup {
  display: flex;
  gap: 12px;
  max-width: 237px;
  margin: 0 auto;
}

.secondInputGroup > div {
  width: 237px;
}

.secondInputGroup > div > input {
  width: 203px;
}

.nextWrapper {
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms {
  text-align: center;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.termsInner {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 12px;
}

.termsInner > a {
  color: var(--tertiary-3);
}

.secondItem {
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.secondItemTitle {
  font-family: inferi-normal, sans-serif;
  font-size: 48px;
}

.secondItemIntro {
  font-family: inferi-normal, sans-serif;
  text-align: center;
  font-size: 20px;
}

.secondButton {
  margin-top: 48px;
}

.thirdItem {
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.thirdItemIntro {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 24px;;
  margin: 0 auto 24px;
  text-align: center;
}

.tagsWrapper {
  margin: 8px auto 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  width: 40%;
}

.showMore {
  margin: 16px 46px;
}

.thirdButton {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

@media only screen and (max-width: 1080px) {
  .thirdItemIntro {
    margin: 0 auto;
  }
  .tagsWrapper {
    width: 100%;
  }
  .mobileThird {
    margin-top: 0;
  }
}

.forthItem {
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.forthItemIntro {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 24px;;
  margin: 0 auto;
  text-align: center;
}

.forthOptions {
  margin-top: 32px;
  display: flex;
  flex-flow: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.forthOption {
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-family: basis-grotesque-pro, sans-serif;
  color: var(--tertiary-3);
  background-color: var(--tertiary-4);
  line-height: 20px;
  cursor: pointer;
}

.selectedOption {
  background-color: var(--secondary-2);
}

.landingBall {
  position: absolute;
  z-index: 100;
  top: 80px;
  left: 100px;
}

.landingMan {
  position: absolute;
  z-index: 100;
  top: 350px;
  left: 200px;
}

.landingHand {
  position: absolute;
  z-index: 100;
  bottom: 30px;
  left: 50px;
}

.landingApple {
  position: absolute;
  z-index: 100;
  top: 100px;
  right: 60px;
}

.landingBook {
  position: absolute;
  z-index: 100;
  bottom: 150px;
  right: 150px;
}

.secondMoon {
  position: absolute;
  z-index: 100;
  top: 80px;
  left: 20px;
}

.secondChromo {
  position: absolute;
  z-index: 100;
  bottom: 120px;
  left: 250px;
}

.secondCloud {
  position: absolute;
  z-index: 100;
  top: 80px;
  right: 200px;
}

.secondMeditation {
  position: absolute;
  z-index: 100;
  bottom: 80px;
  right: 100px;
}

.forthCandle {
  position: absolute;
  z-index: 100;
  top: 350px;
  left: 220px;
}

.forthMan {
  position: absolute;
  z-index: 100;
  top: 80px;
  right: 100px;
}

.forthUp {
  position: absolute;
  z-index: 100;
  bottom: 180px;
  right: 200px;
}