.header {
  padding: 22px 60px;
  position: relative;
}

@media only screen and (max-width: 1080px) {
  .header {
    padding: 12px 25px;
  }
}

.headerInner {
  display: flex;
  justify-content: space-between;
}

.title {
  font-family: inferi-normal, sans-serif;
  margin-left: 10px;
  font-size: 20px;
}

.menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rightItems {
  display: flex;
  gap: 32px;
  align-items: center;
}

.rightItems > button {
  padding: 12px 24px;
}

.rightItems > a {
  cursor: pointer;
  text-decoration: underline;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 12px;
  color: var(--primary-3);
}

.rightItemsWhite > a {
  color: var(--tertiary-4);
}

.headerDropdown {
  position: absolute;
  z-index: 1000;
  width: 100%;
  left: 0;
  padding: 12px 24px;
  height: calc(100vh - 76px);
}

.mt8 {
  margin-top: 8px;
}

.copiedPopup {
  position: absolute;
  z-index: 5;
  padding: 8px 12px;
  background-color: white;
  color: black;
  left: -6px;
  bottom: 17px;
  border-radius: 8px;
  white-space: nowrap;
}

@media only screen and (max-width: 1080px) {
  .copiedPopup {
    bottom: -10px;
    left: 110px;
  }
}
