.tag {
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-family: basis-grotesque-pro, sans-serif;
  color: var(--tertiary-3);
  background-color: var(--tertiary-4);
  line-height: 20px;
  cursor: pointer;
}

.selectedTag {
  background-color: var(--secondary-2);
}

.deselect {
  margin-left: 4px;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
  .tag {
    padding: 6px 8px;
    font-size: 14px;
  }
}