.modalWrapper {
  padding: 21px 26px 56px 50px;
  background-color: var(--primary-3);
  border-radius: 20px;
  z-index: 9999;
  left: 50%;
  max-width: 90%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--tertiary-4);
  width: 50%;
}

@media only screen and (max-width: 1400px)  {
  .modalWrapper {
    width: 70%;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1600px)  {
  .modalWrapper {
    width: 60%;
  }
}

.close {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.modalContent {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media only screen and (max-width: 1080px)  {
  .modalContent {
    flex-flow: column nowrap;
    gap: 16px;
  }
}

.modalContent > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 24px;
}

.modalDesc {
  margin-top: 16px;
  font-family: basis-grotesque-pro, sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.modalButton {
  margin-top: 48px;
}

