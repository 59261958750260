.button {
  border-radius: 25px;
  padding: 12px 14px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  font-family: basis-grotesque-pro, sans-serif;
}

.button:disabled {
  opacity: 0.5;
}

.bordered {
  border: 1px solid;
}

.outlined {
  background-color: transparent;
  border: 1px solid var(--secondary-3);
  color:  var(--secondary-3);
}